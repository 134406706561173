<template>
    <div>
        <Overview 
            :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
            :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
            :sort="'created_at'"
            :itemNameField="'number'"
            :itemService=itemService
            :itemFormRef=itemFormRef
            :sortOrder="2"
            modalWidth="1200px"
            :selectFields=selectFields
            :filterOptions="filterOptions"
            :indexOnMount="false"
            :autoSaveEnabled="true"
        >   
        <template #columns>
            <Column field="vessel.name" :header="$tc('navigation.vessels', 1)" style="width: 25%"></Column>
            <Column field="number" :header="$t('navigation.voyages')" style="width: 120px" :sortOrder="-1"></Column>
            <Column field="carrier.relation.name" :header="$tc('navigation.carriers', 1)" style="width: 20%"></Column>
            <Column field="region_type.name" :header="$t('overview.type')" style="width: 120px"></Column>
            <Column field="voyage_status_id" header="Booking status" style="width: 120px">
                <template #body="slotProps"> 
                    <span class="badge" :data-status="$t('voyage_status.'+slotProps.data.voyage_status_id).toLowerCase()">
                        {{slotProps.data.voyage_status_id ? $t('voyage_status.'+slotProps.data.voyage_status_id) : ''}}
                    </span>
                </template>
            </Column>
            <Column field="voyage_legacy_options.shipload_status_id" header="Shipload status" style="width: 120px">
                <template #body="slotProps">
                    <span class="badge" v-if="slotProps.data.voyage_legacy_options" :data-status="$t('voyage_status.'+slotProps.data.voyage_legacy_options.shipload_status_id).toLowerCase()">
                        {{slotProps.data.voyage_legacy_options && slotProps.data.voyage_legacy_options.shipload_status_id ? $t('voyage_status.'+slotProps.data.voyage_legacy_options.shipload_status_id) : ''}}
                    </span>
                </template>
            </Column>
            <Column field="created_at" :header="`Created`" style="width: 120px" :sortOrder="-1">
                <template #body="slotProps"> {{formatDate(slotProps.data.created_at, 'shortDate')}} </template>
            </Column>
        </template>

        <template #form>
            <VoyageForm ref="ItemForm" :itemData="item" />
        </template>
        </Overview>
    </div>
</template>

<script>
import Overview from '@/components/Overview.vue';

import voyageService from '@/services/VoyageService';
import vesselService from '@/services/VesselService';
import Column from '@/components/column';
import VoyageForm from '@/components/voyage/VoyageFormComponent.vue';

export default {
    components: {
        Overview,
        Column,
        VoyageForm
    },
    data() {
        return {
            item: {},
            itemService: voyageService,
            itemFormRef: null,
            filterOptions: [
                {
                    field: 'vessel_id',
                    service: vesselService,
                    name: this.$t('navigation.vessels'),
                    multiple: true
                },
                {
                    field: 'voyage_status_id',
                    checkboxes: [{id: 1, name: this.$t('voyage_status.1')}, {id: 2, name: this.$t('voyage_status.2')}, {id: 3, name: this.$t('voyage_status.3')}, {id: 4, name: this.$t('voyage_status.4')}],
                    name: 'Booking status',
                    is_on: [1, 1, 0, 0]
                },
                {
                    field: 'voyageLegacyOptions.shipload_status_id',
                    checkboxes: [{id: 1, name: this.$t('voyage_status.1')}, {id: 2, name: this.$t('voyage_status.2')}, {id: 3, name: this.$t('voyage_status.3')}, {id: 4, name: this.$t('voyage_status.4')}],
                    name: 'Shipload status'
                },
                {
                    field: 'region_type_id',
                    radio: [{id: 1, name: this.$t('service.1')}, {id: 2, name: this.$t('service.2')}],
                    name: this.$t('overview.type')
                }
            ],
            selectFields: [
                "id",
                "number",
                "created_at",
                "region_type_id",
                "vessel_id",
                "carrier_id",
                "voyage_status_id",
                {
                    "voyageLegacyOptions": [
                        "id",
                        "voyage_id",
                        "shipload_status_id"
                    ]
                },
                {
                    "vessel": [
                        "id",
                        "code",
                        "name"
                    ]
                },
                {
                    "regionType": [
                        "id",
                        "name"
                    ]
                },
                {
                    "carrier": [
                        "id",
                        "relation_id",
                        {
                            "relation": [
                                "id",
                                "name"
                            ]
                        }
                    ]
                }
            ]
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>